import React, { useEffect } from "react"
import { message, Row, Spin } from "antd"
import { useIntl } from "gatsby-plugin-intl"

import Template from "../layouts/base"
import withAuth from "../components/withAuthHoc"
import LocalizedAniLink from "../components/localizedAniLink"
import authFetch from "../services/network"
import { getHomeItems } from "../services/home"
import {
  addCombinedIngestField,
  ingestHasError,
  ingestIsActive,
  ingestIsCreating,
  removeVersions,
} from "../services/ingest"
import { checkPermissions } from "../services/auth/permissions/permissions"
import useStateObject from "../hooks/useStateObject"

const HomePage = () => {
  const intl = useIntl()

  const [state, setState] = useStateObject({
    loading: false,
    ingestCounters: {
      REAL_TIME: {
        active: 0,
        inactive: 0,
      },
      // NEAR_REAL_TIME: {
      //   active: 0,
      //   inactive: 0,
      // },
      BATCH: {
        active: 0,
        inactive: 0,
      },
      METADATA: {
        active: 0,
        inactive: 0,
      },
    },
  })

  const hasIngestPermissions = checkPermissions(["ingest:read"])

  useEffect(() => {
    if (hasIngestPermissions) {
      setState({ loading: true })
      authFetch(`${process.env.GATSBY_CONF_API_URL}/ingests/`)
        .then(response => {
          if (response.status !== 200) {
            return Promise.reject(response)
          } else {
            setState({ loading: false })
            return response
              .json()
              .then(response => {
                const ingestsWithoutVersion = removeVersions(response)
                return addCombinedIngestField(
                  ingestsWithoutVersion,
                  response
                ).filter(ingest => !ingest.linked_alias)
              })
              .then(ingests => setIngestCounters(ingests))
          }
        })
        .catch(() => {
          message.error(intl.formatMessage({ id: "error-loading-ingests" }))
          setState({ loading: false })
        })
    }
  }, [])

  const setIngestCounters = (ingests = []) => {
    if (Array.isArray(ingests)) {
      const { ingestCounters } = state
      ingests.forEach(ingest => {
        if (ingest.setup.type !== "NEAR_REAL_TIME") {
          const ingestTypeCounters = ingestCounters[ingest.setup.type]
          if (ingestIsActive(ingest)) {
            ingestTypeCounters.active += 1
          } else if (!ingestIsCreating(ingest) && !ingestHasError(ingest)) {
            ingestTypeCounters.inactive += 1
          }
          ingestCounters[ingest.setup.type] = Object.assign(
            {},
            ingestCounters[ingest.setup.type],
            ingestTypeCounters
          )
        }
      })
      setState({ ingestCounters })
    }
  }

  const getLabelForIngestStatus = key => {
    const labelForIngestStatus = {
      active: intl.formatMessage({ id: "ingestions-state-actives" }),
      inactive: intl.formatMessage({ id: "ingestions-state-inactives" }),
    }
    return labelForIngestStatus[key]
  }

  const getLabelForIngestType = key => {
    const labelForIngestType = {
      REAL_TIME: "Real Time",
      NEAR_REAL_TIME: "Near Real Time",
      BATCH: "Batch",
      METADATA: "Metadata",
    }
    return labelForIngestType[key]
  }

  const renderIngestStatus = (status, value) => {
    const ingestStatus = {
      active: (
        <div
          title={intl.formatMessage({
            id: "home-ingests-status-active",
          })}
          className={`ingest-status ingest-status--${
            value === 0 ? "empty" : "ok"
          } ingest-status-home`}
        >
          <span className="ingest-value">{value}</span>
        </div>
      ),
      inactive: (
        <div
          title={intl.formatMessage({
            id: "home-ingests-status-no-active",
          })}
          className={`ingest-status ingest-status--${
            value === 0 ? "empty" : "inactive"
          } ingest-status-home`}
        >
          <span className="ingest-value">{value}</span>
        </div>
      ),
    }
    return ingestStatus[status]
  }

  const renderSubItems = (subItems = []) => {
    return subItems.map(subItem => {
      const key = subItem.description.text
      return checkPermissions(subItem.permissions, true) ? (
        subItem.link.href.match(/^http/) ? (
          <a
            href={subItem.link.href}
            rel="noopener noreferrer"
            target="_blank"
            key={key}
            className="home-card-wrapper home-card-wrapper-link"
            style={{ width: "33%", display: "flex" }}
          >
            <div className="home-card-item-icon-wrapper">
              <img
                className="home-card-item-icon"
                src={subItem.description.icon}
                alt="home-card-item-icon"
              />
            </div>
            <div className="home-card-item-content">
              <div className="home-card-item-title">
                {intl.formatMessage({ id: subItem.link.text })}
              </div>
              <span className="home-card-item-description">
                {intl.formatMessage({
                  id: subItem.description.text,
                })}
              </span>
            </div>
          </a>
        ) : (
          <LocalizedAniLink
            to={subItem.link.href}
            key={key}
            className="home-card-wrapper home-card-wrapper-link"
            style={{ width: "33%", display: "flex" }}
          >
            <div className="home-card-item-icon-wrapper">
              <img
                className="home-card-item-icon"
                src={subItem.description.icon}
                alt="home-card-item-icon"
              />
            </div>
            <div className="home-card-item-content">
              <div className="home-card-item-title">
                {intl.formatMessage({ id: subItem.link.text })}
              </div>
              <span className="home-card-item-description">
                {intl.formatMessage({
                  id: subItem.description.text,
                })}
              </span>
            </div>
          </LocalizedAniLink>
        )
      ) : null
    })
  }

  return (
    <Template>
      {hasIngestPermissions ? (
        <Row>
          <h3 className="home-card-title">
            {intl.formatMessage({ id: "home-card-title" })}
          </h3>
          <section style={{ display: "flex" }}>
            {Object.entries(state.ingestCounters).map(([key, value]) => (
              <div className="home-card-wrapper" key={key}>
                <div className="home-card-item home-card-item--status">
                  <div className="home-card-item-ingest-type">
                    {getLabelForIngestType(key)}
                  </div>
                  <div
                    className="test"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {Object.entries(value).map(([key, value]) => (
                      <div
                        className="home-card-item-ingest-status-container"
                        key={key}
                      >
                        {state.loading ? (
                          <Row
                            style={{
                              height: "90px",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Spin size="large" />
                          </Row>
                        ) : (
                          <>
                            <div className="home-card-item-ingest-status-icon">
                              {renderIngestStatus(key, value)}
                            </div>
                            <div className="home-card-item-ingest-status-title">
                              {getLabelForIngestStatus(key)}
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </section>
        </Row>
      ) : null}
      <Row>
        {getHomeItems().map(item =>
          checkPermissions(item.permissions, true) ? (
            <section key={item.title}>
              <h3 className="home-card-title">
                {intl.formatMessage({ id: item.title })}
              </h3>
              <div className="card-container" style={{ display: "flex" }}>
                {renderSubItems(item.items, item.title)}
              </div>
            </section>
          ) : (
            ""
          )
        )}
      </Row>
    </Template>
  )
}

export default withAuth(HomePage)
