import homeIngestIcon from "../../assets/images/ic-card-ingestas.png"
import homeDictionaryIcon from "../../assets/images/ic-card-diccionario.png"
import homeCreateIngestIcon from "../../assets/images/ic-card-crearingestas.png"
import homeGrafanaIcon from "../../assets/images/img_grafana.png"
import homeJupyterIcon from "../../assets/images/img_jupyter.png"
import homeSupersetIcon from "../../assets/images/img_superset.png"
import { generateDeploymentHostname } from "../network"

export function getHomeItems() {
  return [
    {
      title: "home-menu-ingests-title",
      permissions: ["ingest:read", "dictionary:write", "ingest:write"],
      items: [
        {
          permissions: ["ingest:read"],
          link: {
            href: "/ingest",
            text: "home-menu-ingests-list-link",
          },
          description: {
            icon: homeIngestIcon,
            text: "home-menu-ingests-list-description",
          },
        },
        {
          permissions: ["ingest:write"],
          link: {
            href: "/ingest/options",
            text: "home-menu-ingests-create-link",
          },
          description: {
            icon: homeCreateIngestIcon,
            text: "home-menu-ingests-create-description",
          },
        },
        {
          permissions: ["dictionary:write"],
          link: {
            href: "/dictionary/form",
            text: "home-menu-dictionary-create-link",
          },
          description: {
            icon: homeDictionaryIcon,
            text: "home-menu-dictionary-create-description",
          },
          adminOnly: true,
        },
      ],
    },
    {
      title: "home-menu-data-title",
      permissions: ["monitoring:access", "consumer:read", "jupyter:access"],
      items: [
        {
          permissions: ["monitoring:access"],
          link: {
            href: `https://${generateDeploymentHostname("monitoring")}`,
            text: "home-menu-data-grafana-link",
          },
          description: {
            icon: homeGrafanaIcon,
            text: "home-menu-data-grafana-description",
          },
        },
        {
          permissions: ["superset:access"],
          link: {
            href: `https://${generateDeploymentHostname("superset")}/`,
            text: "home-menu-data-timeseries-link",
          },
          description: {
            icon: homeSupersetIcon,
            text: "home-menu-data-timeseries-description",
          },
        },
        {
          permissions: ["jupyter:access"],
          link: {
            href: "/consume/jupyter",
            text: "home-menu-data-jupyter-link",
          },
          description: {
            icon: homeJupyterIcon,
            text: "home-menu-data-jupyter-description",
          },
        },
        {
          link: {
            href: "/tutorials/rapidminer",
            text: "home-menu-data-rapidminer-link",
          },
          description: {
            icon: "tool",
            text: "home-menu-data-rapidminer-description",
          },
        },
        {
          link: {
            href: "/tutorials/excel-addin",
            text: "home-menu-data-excel-link",
          },
          description: {
            icon: "file-excel",
            text: "home-menu-data-excel-description",
          },
        },
        {
          link: {
            href: "/tutorials/dev-api",
            text: "home-menu-data-api-link",
          },
          description: {
            icon: "code",
            text: "home-menu-data-api-description",
          },
        },
      ],
    },
  ]
}
